import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageBanner from "../components/page-banner"
import TextRightLeft from "../components/text-right-left"
import SaleIcons from "../components/sale-icons"

const Page = ({ data }) => {
  const post = data.wordpressMainPage

  return (
    <Layout>
      <SEO post={post} />
      <PageBanner post={post} />
      <TextRightLeft post={post} firstLeft={true} />
      <SaleIcons />
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    wordpressMainPage(name: {eq: "quero-vender"}) {
      ...MainPageFull
    }
  }
`
