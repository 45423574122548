import React from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"

const SaleIcons = () => {
  return (
    <section className="sale-icons-component is-margin-vertical-12">
      <div className="container is-padding-horizontal-4-touch">
        <div className="columns is-centered is-gapless">
          <div className="column is-6">
            <div className="has-text-centered is-margin-bottom-8">
              <h2 className="title is-2">
                O segmento de alimentação saudável é estratégico na economia do país. Entenda o porquê:
              </h2>
            </div>
          </div>
        </div>

        <div className="is-margin-bottom-6 is-padding-horizontal-10 is-padding-horizontal-0-touch">
          <div className="columns">
            <div className="column">
              <IconItem
                src="/images/icons/vender-1.svg"
                title="O Brasil é o 4º maior mercado de alimentação saudável do mundo;" />
            </div>
            <div className="column">
              <IconItem
                src="/images/icons/vender-2.svg"
                title="Atualmente, a Superbom é Top of Mind para o segmento de alimentos vegetarianos e veganos em um mercado que está em constante crescimento;" />
            </div>
            <div className="column">
              <IconItem
                src="/images/icons/vender-3.svg"
                title="O mercado de alimentos saudáveis cresceu 98% nos últimos 5 anos;" />
            </div>
            <div className="column">
              <IconItem
                src="/images/icons/vender-4.svg"
                title="Com produtos de alto giro e de extremo valor agregado, a Superbom possui um portfólio com mais de 100 sku’s distribuídos em diversas linhas de produtos diferentes." />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const IconItem = ({ src, title }) => {
  return (
    <div className="contact-item">
      <div className="contact-item-icon is-margin-bottom-4 has-text-centered">
        <LazyLoadImage src={src} alt={title} style={{ height: 70 }} />
      </div>
      <h3 className="is-size-6 has-text-weight-extrabold is-margin-bottom-2 has-text-centered">
        {title}
      </h3>
    </div>
  )
}

export default SaleIcons
